export const config = {
    RPC_URL: 'https://namada-se-rpc.cosmicvalidator.com',
    REST_URL: 'https://namada-se-api.cosmicvalidator.com',
    // EXPLORER_URL: 'https://www.mintscan.io/omniflix',
    // STAKING_URL: 'https://flix.omniflix.co/stake',
    NETWORK_NAME: 'Namada',
    NETWORK_TYPE: 'testnet',
    CHAIN_ID: 'shielded-expedition.88f17d1d14',
    TOKEN_ADDRESS: 'tnam1qxvg64psvhwumv3mwrrjfcz0h3t3274hwggyzcee',
    // CHAIN_NAME: 'OmniFlix Hub',
    // COIN_DENOM: 'FLIX',
    // COIN_MINIMAL_DENOM: 'uflix',
    COIN_DECIMALS: 6,
    // PREFIX: 'omniflix',
    // COIN_TYPE: 118,
    // COSMOSTAION: 'omniflix',
    // COINGECKO_ID: 'omniflix-network',
    // GAS_PRICE_STEP_LOW: 0.001,
    // GAS_PRICE_STEP_AVERAGE: 0.0025,
    // GAS_PRICE_STEP_HIGH: 0.025,
    // FEATURES: ['ibc-transfer', 'ibc-go'],
};

export const DEFAULT_PAGE = 1;
